<template>
  <div class="w">

    <!-- <video autoplay controls src="https://read.culturalcloud.net/wyy-book/video/初心如槃-百名共产党人故事/002.mp4" style="width: 100%;"></video> -->
    <h2 class="title">{{ content.title }}</h2>
    <commom-video v-if="content.link" :src="content.link" :poster="content.titleImg"></commom-video>
    <el-tabs v-if='content.type=="党建视频"' style="margin-top: 50px;" v-for="(item, index) in tabs" :key="index" v-model="activeName" type="card">
      <el-tab-pane :label="item" name="first" v-if="index === 0">
        <p> {{ content.txt ? ccontent.txt : '暂无简介' }}</p>
        <p style=" margin-top: 30px;color: #ccc; font-size: 12px;">播放量:{{ content.view ? content.view : 0 }}</p>

      </el-tab-pane>
      <el-tab-pane :label="item" name="first" v-else>
        <el-row :gutter="20">
          <el-col @click.native="$router.push({ path: '/video/' + j.id })" :span="6" :offset="0" v-for="j in recommend"
            :key="j.id">
            <div class="list">
              <div class="img">
                <img :src="j.titleImg" alt="">
              </div>
              <div class="info">
                <p class="es-1 title"> {{ j.title }}</p>
                <p class="es-2 sub"> {{ j.description ? j.description : '暂无简介' }}</p>
                <p class="es-1 view"> 观看次数:{{ j.view }}</p>
              </div>
            </div>
          </el-col>

        </el-row>

      </el-tab-pane>
    </el-tabs>
    <!-- <Pagination/> -->
  </div>
</template>

<script>
import { getVideoDetail } from "@/api";
import CommomVideo from '@/components/CommomVideo.vue'

export default {
  name: 'video-detail',
  components: {
    CommomVideo
  },
  data() {
    return {
      content: {},
      tabs: ['视频简介', '视频推荐'],
      activeName: 'first',
      recommend: []
    }
  },
  watch: {
    '$route'() {
      document.documentElement.scrollTop = 0;
      this.getData()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      getVideoDetail(this.$route.params.id).then(res => {
        this.content = res.data
        this.recommend = res.recommend
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 32px;
  line-height: 64px;
  font-weight: bold;
}

.list {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: .2);
    transition: all ease-in-out .3s;
  }

  .img {

    width: 30%;
    height: 100px;
    // margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill
    }
  }

  .info {
    width: 68%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 32px;
    }

    .sub {
      margin-bottom: auto;
      color: #666;
      word-break: break-all;

    }

    .view {
      color: #ccc;
      font-size: 12px;
    }
  }
}</style>